<template>
  <div>
  </div>
</template>

<style>
</style>

<script>

export default {
  name: 'Quiz',
};
</script>
